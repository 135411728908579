import { ThemeUIStyleObject as TSO } from 'theme-ui';
import { getSpacing } from '@lib/utils';
import { useIconUri } from '#hooks';
import type { ResourceGridStyles as RGS } from './types';

export const useStyles = ({ order }: RGS): typeof Styles => {
  const chevron = useIconUri('Chevron');

  const Styles = {
    ROOT: {
      inlineSize: '100%',
      backgroundColor: 'surface.primary',
      marginInlineStart: 'auto',
      marginInlineEnd: 'auto',
      marginBlockStart: getSpacing(order || 0),
    } as TSO,

    TEXT_CONTAINER: {
      marginBlockEnd: 4,
      textAlign: 'center',
    } as TSO,

    TILE_GRID: {
      rowGap: 5,
      grid: [null, null, 'auto/repeat(15,1fr)'],
      '& > *': {
        gridColumn: ['1/-1', 'span 5'],
      },
    } as TSO,

    FILTER_CONTAINER: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBlockEnd: 5,
      marginInlineStart: [0, '0px'],
      marginInlineEnd: [0, '0px'],
    } as TSO,

    SELECT: {
      variant: 'text.input',
      inlineSize: ['100%', '20.5em'],
      paddingInlineStart: '1.5ch',
      paddingInlineEnd: '1.5ch',
      paddingBlockStart: '1ex',
      paddingBlockEnd: '1ex',
      position: 'relative',
      margin: '1px',
      border: 'input.default',
      borderRadius: 2,
      cursor: 'pointer',
      resize: 'vertical',
      appearance: 'none',
      background: `url(${chevron}) calc(100% - 1.9ch) 56%/20px white no-repeat`,
      inlineWidth: ['100%', '20%'],
      '&[aria-invalid=true]:not(:focus)': {
        margin: '0px',
        border: 'input.error',
      },
    } as TSO,

    PAGINATION: {
      display: 'flex',
      justifyContent: 'center',
      marginBlockStart: 5,
    } as TSO,
  };

  return Styles;
};
