import TestimonialTileGrid from './Testimonial';
import DefaultTileGrid from './DefaultTileGrid';
import CardTileGrid from './CardTileGrid';
import ResourceTileGrid from './ResourceTileGrid';
import { TileGridVariant } from '#constants';

import type { FC } from 'react';
import type { TileGridProps as Props } from './types';

const TileGrid: FC<Props> = ({ variant, ...props }) => {
  switch (variant) {
    case TileGridVariant.DEFAULT:
      return <DefaultTileGrid {...props} />;
    case TileGridVariant.CARDS:
      return <CardTileGrid {...props} />;
    case TileGridVariant.TESTIMONIAL:
      return <TestimonialTileGrid {...props} />;
    case TileGridVariant.RESOURCE:
      return <ResourceTileGrid {...props} />;
    default:
      throw Error(`Variant type ${variant} is not a Tile Grid variant.`);
  }
};

export default TileGrid;
