import Tile from '@components/Tile';
import { Grid, H2, H5, StarRating } from '#ui';
import { getSpacing } from '@lib/utils';
import { propsValidator } from '#utils';

import type { FC } from 'react';
import type { DefaultTileGridProps as Props } from './types';

const DefaultTileGrid: FC<Props> = ({
  title,
  hideTitle,
  tilesCollection,
  tileGridCopy,
  columnsAtDesktop,
  superheading,
  leftAlignTitle,
  order,
  rating,
  ratingCopy,
  starRatingLink,
  ...entry
}) => {
  const valid = propsValidator(entry, { title });
  if (!valid) return null;

  const textAlign = leftAlignTitle ? 'left' : 'center';
  const gridColumn = leftAlignTitle ? [null, '1/6', '1/10'] : [null, '1/-1'];

  return (
    <Grid
      subgrid
      as="section"
      className="tileGrid"
      sx={{
        contain: 'content',
        marginBlockStart: getSpacing(order),
      }}
    >
      {superheading && (
        <H5 as="small" sx={{ textAlign }}>
          {superheading}
        </H5>
      )}
      <H2
        className={hideTitle ? 'global-sr' : undefined}
        col={gridColumn}
        sx={{ textAlign, maxWidth: 9, marginInline: 'auto' }}
      >
        {title}
      </H2>
      {rating && (
        <div
          sx={{
            display: ['none', 'flex'],
            flexWrap: 'wrap',
            justifyContent: textAlign,
            alignItems: 'center',
          }}
        >
          <StarRating
            rating={rating}
            ratingCopy={ratingCopy}
            link={starRatingLink}
          />
        </div>
      )}
      {tileGridCopy && (
        <div
          sx={{
            flex: '100%',
            textAlign,
            gridColumn,
            maxWidth: 10,
            marginBlockStart: 2,
            marginInline: 'auto',
          }}
        >
          <p
            sx={{
              fontSize: 3,
              fontWeight: 'regular',
              lineHeight: 'copy',
            }}
          >
            {tileGridCopy}
          </p>
        </div>
      )}
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            '1fr',
            `repeat(${columnsAtDesktop === '1' ? 1 : 2}, 1fr)`,
            `repeat(${columnsAtDesktop}, 1fr)`,
          ],
          paddingInlineStart: [2, '0px'],
          paddingInlineEnd: [2, '0px'],
          paddingBlockStart: 5,
          gridGap: 3,
          '.tile': {
            gridItem: 'span 1',
          },
        }}
      >
        {!!tilesCollection?.items &&
          tilesCollection.items.map((item, key) => (
            <Tile key={key} {...item} />
          ))}
      </div>
    </Grid>
  );
};

export default DefaultTileGrid;
