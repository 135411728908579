import { Grid, Block, Text, Button, H2 } from '#ui';
import { useStyles } from './resource.styles';
import { FC, useEffect, useState } from 'react';
import { default as ResourceTile } from '@components/Tile';
import { propsValidator } from '#utils';

import type {
  ResourceTileGridProps as Props,
  DataImportProps,
} from './types';
import type { ContentfulTile } from '@lib/types';

const ResourceTileGrid: FC<Props> = ({
  title,
  hideTitle,
  showInGroupsOf,
  tileGridCopy,
  order,
  ...props
}) => {
  const valid = propsValidator(props, { title });
  if (!valid) return null;

  const Styles = useStyles({ order });

  // id of the current tilegrid to request from Contentful
  const componentId = props.sys.id;

  const [allTiles, setAllTiles] = useState<ContentfulTile[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedCategory, setCategory] = useState('all');
  const [viewableTiles, setViewable] = useState<ContentfulTile[]>([]);
  const [categoryList, setCategoryList] = useState<string[]>();
  const [showCount, setShowCount] = useState(showInGroupsOf);
  const [loadMore, setLoadMore] = useState<boolean>(false);

  useEffect(() => {
    setIsLoading(true);
    fetch(`/api/cms/getResourceGrid/${componentId}`)
      .then((data) => data.json())
      .then((data) => loadTiles(data))
      .then(() => setIsLoading(false));
  }, []);
  
  useEffect(() => {
    viewableTiles.length ? moreTiles(viewableTiles) : moreTiles(allTiles);
  }, [showCount, viewableTiles]);

  const moreTiles = (array: ContentfulTile[]) => {
    array.length > showCount ? setLoadMore(true) : setLoadMore(false);
  };

  const filterTiles = (category: string) => {
    setViewable(allTiles.filter((tile) => tile.resourceType === category));
  };

  const loadTiles = (data: DataImportProps) => {
    const tiles = data.tileGrid.resourceTilesCollection.items;
    setAllTiles(tiles);

    const newCategoryList = [...new Set(tiles.map(tile => tile.resourceType))] as string[];
    const catListNoNullValues = newCategoryList.filter(element => element !== null);
    setCategoryList(catListNoNullValues);

    moreTiles(tiles);
  };

  const updateResource = (resource: string) => {
    setShowCount(showInGroupsOf);
    if (resource === 'all'){
      setCategory('all');
      setViewable([]);
    } else {
      setCategory(resource);
      filterTiles(resource);
    }
  };

  const displayCat = (category: string) => {
    if (category.includes('Company - ')) return category.replace('Company - ','');
    else return category;
  }

  return (
    <Grid as="section" sx={Styles.ROOT} subwrap>
      {(!!title || !!tileGridCopy) && 
        <Block sx={Styles.TEXT_CONTAINER}>
          <H2
            className={hideTitle ? "global-sr" : undefined}>
              {title}
          </H2>
          {!!tileGridCopy && (
            <Text col={['2/-2', '4/-4']}>
              {tileGridCopy}
            </Text>
          )}
        </Block>
      }
      {(!!categoryList?.length) &&
        <Block sx={Styles.FILTER_CONTAINER}>
          <select
            sx={Styles.SELECT}
            onChange={(e) => updateResource(e.target.value)}
            value={selectedCategory}
            aria-label="Filter Category"
          >
            <option value="all">Filter Resource by Type</option>
            <option value="all">
              View All Resources ({allTiles.length})
            </option>
            {categoryList.map((category, index) => (
              <option value={category} key={index}>
                {displayCat(category)} ({allTiles?.filter(item => item.resourceType === category).length})
              </option>
            ))}
          </select>
        </Block>
      }
      {isLoading ? (
        <Block>Loading...</Block>
      ) : viewableTiles.length ? (
        <Grid sx={Styles.TILE_GRID} noPadding>
          {viewableTiles.slice(0, showCount).map((tile, key) => (
            <ResourceTile key={key} {...tile} />
          ))}
        </Grid>
      ) : allTiles?.length ? (
        <Grid sx={Styles.TILE_GRID} noPadding>
          {allTiles.slice(0, showCount).map((tile, key) => (
            <ResourceTile key={key} {...tile} />
          ))}
        </Grid>
      ) : (
        <Block>No Tiles Found</Block>
      )}
      {loadMore && (
        <Block sx={Styles.PAGINATION}>
          <Button onClick={() => setShowCount((prev) => prev + 3)}>
            Load More
          </Button>
        </Block>
      )}
    </Grid>
  );
};

export default ResourceTileGrid;
