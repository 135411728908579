import Tile from '@components/Tile';
import { H2, H5 } from '#ui';
import { getSpacing } from '@lib/utils';
import { propsValidator } from '#utils';

import type { FC } from 'react';
import type { CardTileGridProps as Props } from './types';

const CardTileGrid: FC<Props> = ({
  title,
  hideTitle,
  tilesCollection,
  tileGridCopy,
  columnsAtDesktop,
  superheading,
  order,
  ...entry
}) => {
  const valid = propsValidator(entry, { title });
  if (!valid) return null;

  return (
    <section
      className="tileGrid"
      sx={{
        contain: 'content',
        display: 'flex',
        flexFlow: 'column wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBlockStart: getSpacing(order),
      }}
    >
      {superheading && <H5 as="small">{superheading}</H5>}
      <H2 className={hideTitle ? 'global-sr' : undefined}>{title}</H2>
      <div
        sx={{
          display: 'grid',
          gridTemplateColumns: [
            '1fr',
            'repeat(2, 2fr)',
            `repeat(${columnsAtDesktop}, 1fr)`,
          ],
          paddingInlineStart: [2, 0],
          paddingInlineEnd: [2, 0],
          paddingBlockStart: [null, 4],
          paddingBlockEnd: [4, null],
          gridGap: 5,
          '.tile': {
            gridItem: 'span 1',
          },
        }}
      >
        {tilesCollection?.items &&
          tilesCollection.items.map((item, key) => (
            <Tile key={key} {...item} />
          ))}
      </div>
      {tileGridCopy && (
        <div
          sx={{
            flex: '100%',
            textAlign: 'center',
            marginBlockStart: 2,
          }}
        >
          <p
            sx={{
              fontSize: 3,
              fontWeight: 'regular',
              lineHeight: 'copy',
            }}
          >
            {tileGridCopy}
          </p>
        </div>
      )}
    </section>
  );
};

export default CardTileGrid;
