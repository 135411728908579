import { Grid, Block, Text, H5, Inline, H3, Icon, HighlightText } from '#ui';
import { useStyles } from './testimonial.styles';
import { FC, useState } from 'react';
import { propsValidator } from '#utils';

import type { TestimonialTileGridProps as Props } from './types';

const TestimonialTileGrid: FC<Props> = ({
  title,
  hideTitle,
  titleHighlight,
  stackOnMobile,
  tilesCollection,
  columnsAtDesktop,
  superheading,
  leftAlignTitle,
  order,
  ...entry
}) => {
  const valid = propsValidator(entry, { title });
  if (!valid) return null;

  const textAlign = leftAlignTitle ? 'left' : 'center';
  const gridColumn = leftAlignTitle
    ? [null, '1/6', '1/10']
    : [null, '2/-2', '3/-3'];
  const Styles = useStyles({ columnsAtDesktop, order });
  const [firstTile, ...hiddenTiles] = tilesCollection.items || [];

  const [showTiles, setShowTiles] = useState<boolean>();

  return (
    <Grid
      as="section"
      sx={Styles.ROOT}
      subwrap
      wrap
      noPadding
    >
      {!!superheading && (
        <H5
          as='small' 
          sx={{ textAlign: textAlign, display: 'block' }}>
            {superheading}
        </H5>
      )}
      <H3
        as="h2"
        col={gridColumn}
        className={hideTitle ? "global-sr" : undefined}
        sx={hideTitle ? undefined : { textAlign: textAlign, ...Styles.TITLE }}
      >
        {title} {titleHighlight && <HighlightText>{titleHighlight}</HighlightText>}
      </H3>
      {!!tilesCollection.items.length && (
        <div sx={Styles.TILE_GRID}>
          <Block
            as="article"
            key={firstTile.sys.id}
            aria-label={firstTile?.heading || undefined}
          >
            <Block sx={Styles.CITATION_BLOCK}>
              <Icon
                as={firstTile.twitterSource ? 'Twitter' : 'Open Quotes'}
                color={firstTile.twitterSource ? 'TwitterBlue' : 'Black'}
              />
              <Block sx={Styles.TESTIMONIAL_BLOCK}>
                {firstTile.heading && (
                  <Text>{firstTile.heading}</Text>
                )}
                {firstTile.testimonialJobTitle && (
                  <Text>{firstTile.testimonialJobTitle}</Text>
                )}
              </Block>
            </Block>
              {!!firstTile.description && (
                <Text sx={Styles.TESTIMONIAL_QUOTE}>
                  <Inline as="q">{firstTile.description}</Inline>
                </Text>
              )}
          </Block>
          {!stackOnMobile && (
            <button
              onClick={() => setShowTiles((prevState) => !prevState)}
              sx={{
                display: ['inline-flex', 'none'],
                '::after': {
                  transform: showTiles ? 'rotate(180deg)' : 'rotate(0deg)',
                },
                ...Styles.BUTTON,
              }}
              aria-label={showTiles ? 'Hide Quotes' : 'Show More Quotes'}
            >
              {showTiles ? 'Hide Quotes' : 'Show More Quotes'}
            </button>
          )}
          {!!hiddenTiles.length &&
            hiddenTiles.map((item) => (
              <Block
                sx={
                  !stackOnMobile
                    ? showTiles
                      ? { display: 'block' }
                      : { display: ['none', 'block'] }
                    : { display: 'block' }
                }
                as="article"
                key={item.sys.id}
                aria-label={item?.heading || undefined}
              >
                <Block sx={Styles.CITATION_BLOCK}>
                  <Icon
                    as={item.twitterSource ? 'Twitter' : 'Open Quotes'}
                    color={item.twitterSource ? 'TwitterBlue' : 'Black'}
                  />
                  <Block sx={Styles.TESTIMONIAL_BLOCK}>
                    {item.heading && (
                      <Text>{item.heading}</Text>
                    )}
                    {item.testimonialJobTitle && (
                      <Text>{item.testimonialJobTitle}</Text>
                    )}
                  </Block>
                </Block>
                <Text sx={Styles.TESTIMONIAL_QUOTE}>
                  <Inline as="q">
                    {!!item.description && item.description}
                  </Inline>
                </Text>
              </Block>
            ))}
        </div>
      )}
    </Grid>
  );
};

export default TestimonialTileGrid;
