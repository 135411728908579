import { ThemeUIStyleObject as TSO } from 'theme-ui';
import { getSpacing } from '@lib/utils';

import type { TestimonialGridStyles as TGS } from './types';

export const useStyles = ({
  columnsAtDesktop,
  order,
}: TGS): typeof Styles => {
  const Styles = {
    ROOT: {
      display: 'flex',
      flexFlow: ['column wrap', 'row nowrap'],
      backgroundColor: 'surface.primary',
      marginBlockStart: getSpacing(order || 0),
      paddingInlineStart: [0, 2],
      paddingInlineEnd: [0, 2],
    } as TSO,

    TITLE: {
      fontSize: 5,
      fontWeight: ['extraBold', 'regular'],
    } as TSO,

    TESTIMONIAL_BLOCK: {
      marginInlineStart: '1.75rem',
      'p': {
        fontSize: '.75rem',
        lineHeight: 'heading',

        '&:first-of-type': { marginBlockStart: '0px' },
        '&:nth-of-type(2)': { marginBlockStart: '4px' },
      },
    } as TSO,

    TESTIMONIAL_QUOTE: {
      color: 'black',
      marginBlockStart: 1,
      marginInlineStart: 'calc(25px + 1.75rem)',
    } as TSO,

    CITATION_BLOCK: {
      display: 'flex',
      flexFlow: 'row nowrap',
      alignItems: 'flex-start',
      marginBlockEnd: 0,

      '.IconCore': {
        marginBlockStart: '.25rem',
        inlineSize: '1.625rem',
      },
    } as TSO,

    TILE_GRID: {
      display: 'grid',
      gridTemplateColumns: [
        '1fr',
        `repeat(${columnsAtDesktop === '1' ? 1 : 2}, 1fr)`,
        `repeat(${columnsAtDesktop}, 1fr)`,
      ],
      paddingInlineStart: [null, 4],
      paddingInlineEnd: [null, 4],
      marginBlockStart: '3.75rem',
      gridGap: [5, 6, 8],
      article: {
        padding: ['0px', null],
      },
    } as TSO,

    BUTTON: {
      alignItems: 'center',
      fontSize: 'inherit',
      fontFamily: 'body',
      fontWeight: 'bold',
      border: 'none',
      color: 'lilac.7',
      backgroundColor: 'surface.primary',
      '&::after': {
        display: 'block',
        inlineSize: 1,
        blockSize: 1,
        marginInlineStart: 0,
        marginBlockStart: '.125rem',
        content: '""',
        background:
          'url(\'data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20"><path d="M3,5 10,13 17,5" stroke="%234507FF" stroke-width="2.5" fill="none" /></svg>\') no-repeat',
        transformOrigin: 'center',
      },
    } as TSO,
  };

  return Styles;
};
